.card-product {
  .card-title {
    font-weight: $font-weight-extra-bold;
    color: $primary;
    font-size: $h2-font-size;
  }

  .sort-bar {
    background-color: $gray-100;
  }
}
