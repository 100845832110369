.hyperlink-blue {
    font-size: 14px;
    color:#1B74B9;
    font-weight: $font-weight-normal;
    text-decoration: underline;
}

.hyperlink-black {
  font-size: 14px;
  color: $primary;
  font-weight: $font-weight-normal;
  text-decoration: underline;
}

