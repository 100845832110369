// Component: Autocomplete
// ========================================================================

$black: #22292f !default; // 231f48 22292f
$white: #fff !default;
$body-bg: #f8f8f8 !default;
$body-color: #6e6b7b !default;
$text-muted: #b9b9c3 !default;
$autocomplete-active-color: #f1f1f1;
$theme-dark-body-bg: #161d31 !default;
$theme-dark-body-color: #b4b7bd !default;
$theme-dark-border-color: #3b4253 !default;
$theme-dark-custom-control-border-color: #44405e !default;

//Typography
$theme-dark-headings-color: #d0d2d6 !default;
$theme-dark-label-color: #d0d2d6 !default;
$theme-dark-text-muted-color: #676d7d !default;

//Card
$theme-dark-card-bg: #283046 !default;
$theme-dark-box-shadow: 0 4px 24px 0 rgba($black, 0.24);

//Input
$theme-dark-input-bg: #283046 !default;
$theme-dark-input-placeholder-color: #676d7d !default;
$theme-dark-input-border-color: #404656 !default;
$theme-dark-input-disabled-bg: #24233a !default;
$theme-dark-input-disabled-border-color: #444b60 !default;

// Switch
$theme-dark-switch-bg: #545a6a;
$theme-dark-switch-bg-disabled: #1b2337;

//Table
$theme-dark-table-bg: #283046 !default;
$theme-dark-table-header-bg: #343d55 !default;
$theme-dark-table-row-bg: #283046 !default;
$theme-dark-table-hover-bg: #242b3d !default;
$theme-dark-table-striped-bg: #242b3d !default;

$theme-dark-modal-header-bg: #161d31 !default;
$theme-dark-pagination-bg: #242b3d !default;
$theme-dark-chart-bg: #384056 !default;
$theme-dark-widget-bg: #384056 !default;

.autocomplete-container {
  position: relative;
  .autocomplete-search {
    height: 100%;
    width: 100%;
    background-color: $white;
    &:focus {
      box-shadow: none;
    }
  }
  .suggestions-list {
    z-index: 998;
    list-style: none;
    padding-left: 0;
    border-radius: 0.5rem;
    max-height: 450px;
    height: auto;
    width: 100%;
    position: absolute;
    margin-top: 0.5rem;
    list-style-type: none;
    background-color: $white;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
    .suggestion-item {
      padding: 0.9rem 1rem;
      color: $body-color;
      line-height: 1 !important;
      a {
        color: $body-color;
      }
      &.suggestion-title {
        font-size: 0.85rem;
        color: $text-muted;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        margin-top: 0.75rem;
        margin-bottom: 0;
      }
      &.active,
      &:hover:not(.suggestion-title-wrapper):not(.no-result) {
        background-color: $autocomplete-active-color;
        color: $body-color;
      }

      &:hover:not(.suggestion-title-wrapper):not(.no-result) {
        cursor: pointer;
      }
    }
    &.open-up {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
  }
}

// Dark
.dark-layout {
  .autocomplete-container {
    .suggestions-list {
      background-color: $theme-dark-card-bg;
      .suggestion-item {
        color: $theme-dark-body-color !important;
        &.suggestion-title-wrapper {
          color: $theme-dark-text-muted-color;
        }
        &:not(.suggestion-title-wrapper) {
          &.active,
          &:hover {
            background-color: $theme-dark-body-bg !important;
          }
        }
      }
    }
  }
}
