//
// Table
//


// General styles

.table {

    tbody {
        background-color: $white;
    }

    thead th {
        padding-top: $table-head-spacer-y;
        padding-bottom: $table-head-spacer-y;
        font-size: $table-head-font-size;
        text-transform: $table-head-text-transform;
        letter-spacing: $table-head-letter-spacing;
        border-bottom: $table-border-width solid $table-border-color;
    }

    th {
        font-weight: $table-head-font-weight;
    }

    td {
        .progress {
            height: 3px;
            width: 120px;
            margin: 0;
        }
    }

    td,
    th {
        font-size: $table-body-font-size;
        white-space: nowrap;
    }


    // Vetical align table content

    &.align-items-center {
        td,
        th {
            vertical-align: middle;
        }
    }


   // Styles for dark table

   .thead-dark {
        th {
            background-color: $table-dark-head-bg;
            color: $table-dark-head-color;

            a {
                color: $table-dark-head-color;
            }
        }
    }


    // Styles for light table

    .thead-light {
        th {
            background-color: $table-head-bg;
            color: $table-head-color;

            a {
                color: $table-head-color;
            }
        }
    }
}


// Add transition for hover state

.table-hover {
    tr {
        @include transition($transition-base);
    }
}


// Flush tables

.table-flush {
    td,
    th {
        border-left: 0;
        border-right: 0;
    }

    tbody {
        tr {
            &:first-child {
                td,
                th {
                    border-top: 0;
                }
            }

            &:last-child {
                td,
                th {
                    border-bottom: 0;
                }
            }
        }
    }
}


// Tables inside cards

.card {
    .table {
        margin-bottom: 0;

        td,
        th {
            padding-left: $card-spacer-x;
            padding-right: $card-spacer-x;
        }
    }
}


// Fixes for custom elements inside tables

.table {
    .custom-toggle {
        display: block;
    }
}

.pm-table {
    position: sticky;
    top: 0;
    padding: 10px;
    margin: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: $font-weight-extra-bold;
    color: $primary;
    z-index: 1;
}

.pm-header {
    width: 100%;
    background-color: $white;
    height: 80px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    font-size: 18px;
    color: $gray-800;
    font-weight: $font-weight-extra-bold;
}

.pm-row {
    border-bottom: 1px solid $secondary;
}

.pm-cell {
    padding: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: $font-weight-bold;

    .hyperlink {
      font-size: 14px;
      color:#1B74B9;
      font-weight: 400;
      text-decoration: underline;
    }
}

