@each $color, $value in $theme-colors {
    .custom-checkbox-#{$color} .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }
}

.input-primary {
    border: 1px solid #C2B395 !important;
}

.input-primary-invalid {
    border: 1px solid $danger !important;
}

.input-password {
    border-right: none !important;
}

.input-secondary {
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: transparent;
}

.input-secondary-invalid {
    border-bottom: 1px solid $danger !important;
}

